import React, { createContext, useState, useContext, ReactNode } from "react";

interface SharedStateContextType {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SharedStateContext = createContext<SharedStateContextType | undefined>(
  undefined
);

export const useSharedState = () => {
  const context = useContext(SharedStateContext);
  if (!context) {
    throw new Error(
      "useSharedState debe ser usado dentro de un SharedStateProvider"
    );
  }
  return context;
};

export const SharedStateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <SharedStateContext.Provider value={{ loading, setLoading }}>
      {children}
    </SharedStateContext.Provider>
  );
};
