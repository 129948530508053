// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-primary {
  background-color: #1c3d80 !important;
  border-color: #1c3d80 !important;
}

.btn-outline-primary {
  border-color: #1c3d80 !important;
  color: #1c3d80 !important;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: #243468 !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  background-color: #243468 !important;
  color: #ffff !important;
}

a {
  color: #1c3d80 !important;
}

.loading__container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100svh;
  width: 100%;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
}

.loading__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;EAChC,yBAAyB;AAC3B;;AAEA;;EAEE,oCAAoC;AACtC;;AAEA;;EAEE,oCAAoC;EACpC,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,oCAAoC;EACpC,cAAc;EACd,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC","sourcesContent":[".btn-primary {\n  background-color: #1c3d80 !important;\n  border-color: #1c3d80 !important;\n}\n\n.btn-outline-primary {\n  border-color: #1c3d80 !important;\n  color: #1c3d80 !important;\n}\n\n.btn-primary:hover,\n.btn-primary:active {\n  background-color: #243468 !important;\n}\n\n.btn-outline-primary:hover,\n.btn-outline-primary:active {\n  background-color: #243468 !important;\n  color: #ffff !important;\n}\n\na {\n  color: #1c3d80 !important;\n}\n\n.loading__container {\n  position: fixed;\n  background-color: rgba(0, 0, 0, 0.5);\n  height: 100svh;\n  width: 100%;\n  z-index: 9999;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.loading__spinner {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
