import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { Loading } from "./web/components/Loading/Loading";
import { SharedStateProvider } from "./web/providers/SharedStateContext/SharedStateContext";
import { UrlParamsProvider } from "./web/providers/UrlParamsProvider/UrlParamsProvider";
import { Form } from "./web/components/Form/Form";

function App() {
  return (
    <SharedStateProvider>
      <Loading />
      <UrlParamsProvider>
        <Form />
      </UrlParamsProvider>
    </SharedStateProvider>
  );
}

export default App;
