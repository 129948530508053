import React from "react";
import "./modal.css"
import { PropsModal } from "./interface";

export const Modal: React.FC<PropsModal> = ({
  showModal,
  contentTitle,
  children,
  contentFooter,
  sizes,
  toggleModal,
}) => {
  return (
    <>
      {showModal ? (
        <div
          className="modal"
          tabIndex={-1}
          role="dialog"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className={`modal-dialog ${
              sizes ? `modal-${sizes}` : ""
            } modal-dialog-centered modal-dialog-scrollable`}
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{contentTitle}</h5>
                {toggleModal && (
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={toggleModal}
                  ></button>
                )}
              </div>
              <div className="modal-body">{children}</div>
              {contentFooter ? (
                <div className="modal-footer">{contentFooter}</div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
