import { BodyCustomer } from "../../core/domain/Customer";
import { FormDataCO, FormDataEC } from "../components/Form/interface";
import { UrlParams } from "../providers/UrlParamsProvider/interface";
import {
  ID_STORES_CO,
  ID_STORES_EC,
  TERMS_STORES_CO,
  TERMS_STORES_EC,
} from "./constants";

const isColombianData = (data: FormDataCO | FormDataEC): data is FormDataCO => {
  return (data as FormDataCO).department !== undefined;
};

export const buildRequestBody = (
  data: FormDataCO | FormDataEC,
  params: UrlParams
): BodyCustomer => {
  const requestBody: BodyCustomer = {
    nombreComercial: `${data.firstName.toUpperCase()} ${data.lastName.toUpperCase()}`,
    documento: data.identification,
    codMoneda: "1",
    pais: data.nationality,
    nombreCliente: `${data.firstName.toUpperCase()} ${data.lastName.toUpperCase()}`,
    departamento: isColombianData(data)
      ? data.department.toUpperCase()
      : (data as FormDataEC).province.toUpperCase(),
    ciudad: data.city.toUpperCase(),
    sexo: data.gender,
    telefono: data.phoneNumber,
    email: data.email.toUpperCase(),
    habeasData: "T",
    crmMarca: params.brand.toUpperCase(),
    crmStore: params.store.toUpperCase(),
    fechaNacimiento: new Date(data.birthdayDate),
  };

  return requestBody;
};

const formatDate = () => {
  const currentDate = new Date();
  const formattedDate = currentDate
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

  return formattedDate;
};

const getGender = (gender: string) => {
  if (gender === "I") {
    return null;
  }

  return gender;
};

export const buildRequestBodyWoowUp = (
  data: FormDataCO | FormDataEC,
  params: UrlParams
) => {
  const app =
    params.country === "Colombia"
      ? ID_STORES_CO[params.brand]
      : ID_STORES_EC[params.brand];

  const requestBody = {
    app: app,
    document: data.identification,
    email: data.email,
    tags: "formulario_tienda_fisica",
    birthdate: data.birthdayDate,
    telephone: data.phoneNumber,
    gender: getGender(data.gender),
    first_name: data.firstName,
    last_name: data.lastName,
    custom_attributes: {
      fecha_de_actualizacion_formulario_tiendas: formatDate(),
      tienda_de_suscripcion: params.store,
      tipo_de_documento: data.typeId,
      nacionalidad: data.nationality,
    },
  };

  return requestBody;
};

export const getLinkTerms = (country: string, brand: string): string => {
  if (country === "Colombia") {
    return TERMS_STORES_CO[brand];
  }

  if (country === "Ecuador") {
    return TERMS_STORES_EC[brand];
  }

  return "";
};
