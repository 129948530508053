import { Customers } from "../../core/domain/Customer";
import { fetchData } from "../../infrastructure/httpUtils";
const BASE_URL = "https://www.data.pernine.com.co/p9/crm/";
// const BASE_URL = "http://localhost:3000/p9/crm/";

export const getCustomer = async (
  identification: string,
  country: string
): Promise<Customers[]> => {
  try {
    const customerData = await fetchData(
      `${BASE_URL}${identification}/${country}`,
      "GET"
    );
    return customerData;
  } catch (error) {
    console.error("Error al obtener el cliente:", error);
    throw error;
  }
};

export const updateCustomer = async (
  country: string,
  identification: string,
  body: any
): Promise<any> => {
  try {
    const customerData = await fetchData(
      `${BASE_URL}${country}/${identification}`,
      "PATCH",
      body
    );
    return customerData;
  } catch (error) {
    console.error("Error al actualizar el cliente:", error);
    throw error;
  }
};

export const createCustomer = async (
  country: string,
  body: any
): Promise<Customers[]> => {
  try {
    const customerData = await fetchData(
      `${BASE_URL}create/${country}`,
      "POST",
      body
    );
    return customerData;
  } catch (error) {
    console.error("Error al crear el cliente:", error);
    throw error;
  }
};

export const sendDataWoowUp = async (body: any) => {
  try {
    const customerData = await fetchData(
      "https://events.woowup.com/events/users",
      "POST",
      body
    );
    return customerData;
  } catch (error) {
    console.error("Error al crear el cliente:", error);
    throw error;
  }
};
