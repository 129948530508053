import React from "react";
import { Modal } from "../Modal/Modal";

export const ValidationMessageParams: React.FC = () => {
  return (
    <>
      <Modal showModal sizes="">
        <div className="d-flex flex-column align-items-center gap-3">
          <img
            src="/assets/images/codeQR.svg"
            alt="Escaner QR"
            width={128}
            height={128}
          />
          <h2>Ocurrio un error</h2>
          <div className="text-center">
            <p className="mb-1">
              Por favor vuelve a escanear el código QR. Si el error persiste
              solicita ayuda a tu asesor.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};
