import axios from 'axios';

export async function fetchData(url: string, method?: string, body?: any) {
  try {
    const options = {
      method,
      headers: {
        "cache-control": "no-cache",
				"content-type": "application/json"
      },
      data: JSON.stringify(body),
    };

    const response = await axios(url, options);

    if (response.status < 200 || response.status >= 300) {
      let errorMessage = "Error en la solicitud.";
      try {
        if (response?.data && response?.data?.message) {
          errorMessage = response?.data?.message;
        }
      } catch (error) {}
      throw new Error(errorMessage);
    }

    return response?.data;
  } catch (error) {
    throw error;
  }
}
