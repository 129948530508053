import React, { useRef, useState } from "react";
import { useUrlParams } from "../../providers/UrlParamsProvider/UrlParamsProvider";
import { ColombiaForm } from "./col/ColombiaForm";
import { EcuadorForm } from "./ecu/EcuadorForm";
import { Logo } from "../Logo/Logo";
import { State } from "./interface";
import { Modal } from "../Modal/Modal";

export const Form: React.FC = () => {
  const { params } = useUrlParams();
  const country = params?.country;
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState<State>({
    status: "initial",
    message: "",
  });

  const handleHiddeModal = () => {
    setShowModal(false);
    if (country === "Colombia" && colombiaFormRef.current) {
      colombiaFormRef.current.resetForm();
    } else if (country === "Ecuador" && ecuadorFormRef.current) {
      ecuadorFormRef.current.resetForm();
    }
  };

  const colombiaFormRef = useRef<{ resetForm: () => void }>(null);
  const ecuadorFormRef = useRef<{ resetForm: () => void }>(null);

  const formComponents: Record<string, React.ReactNode> = {
    Colombia: (
      <ColombiaForm
        ref={colombiaFormRef}
        setState={setState}
        setShowModal={setShowModal}
      />
    ),
    Ecuador: (
      <EcuadorForm
        ref={ecuadorFormRef}
        setState={setState}
        setShowModal={setShowModal}
      />
    ),
  };

  const FormComponent =
    country && formComponents[country] ? formComponents[country] : null;

  return (
    <>
      <div className="container-sm d-flex flex-column justify-content-center">
        <section className="mt-5">
          {params && <Logo brand={params.brand} />}
        </section>
        {FormComponent}
      </div>
      {showModal && (
        <Modal showModal sizes="">
          <div className="d-flex flex-column align-items-center gap-4">
            <img
              src={`${
                state.status === "success"
                  ? "/assets/icons/success.svg"
                  : "/assets/icons/error.svg"
              }`}
              width={100}
              height={100}
              alt=""
            />
            <div
              className="text-center"
              dangerouslySetInnerHTML={{ __html: state.message }}
            />
            <button
              className="btn btn-primary btn-md"
              onClick={() => handleHiddeModal()}
            >
              Regresar
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};
