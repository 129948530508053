import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FormDataEC, FormProps } from "../interface";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  alphabeticPattern,
  emailPattern,
  numericPattern,
} from "../../../utils/patterns";
import {
  COUNTRIES,
  MESSAGE_DATA_UPDATED,
  TYPE_IDS_EC,
} from "../../../utils/constants";
import { useUrlParams } from "../../../providers/UrlParamsProvider/UrlParamsProvider";
import {
  buildRequestBody,
  buildRequestBodyWoowUp,
  getLinkTerms,
} from "../../../utils/functions";
import { useSharedState } from "../../../providers/SharedStateContext/SharedStateContext";
import { Department } from "../../../../core/domain/Department";
import { useGetProvinces } from "../../../hooks/DepartmentsHooks";
import {
  createCustomer,
  getCustomer,
  sendDataWoowUp,
  updateCustomer,
} from "../../../services/CustomersService";
import { BodyCustomer } from "../../../../core/domain/Customer";

export const EcuadorForm = forwardRef(
  ({ setState, setShowModal }: FormProps, ref) => {
    const provincesAndCities = useGetProvinces();
    const [urlTerms, setUrlTerms] = useState<string>("");
    const { loading, setLoading } = useSharedState();
    const [cities, setCities] = useState<string[]>([]);
    const {
      reset,
      register,
      watch,
      setValue,
      handleSubmit,
      formState: { errors },
    } = useForm<FormDataEC>({
      defaultValues: {
        termsAndConditions: true,
      },
    });

    const selectedProvince = watch("province");
    const { params } = useUrlParams();

    useEffect(() => {
      const departamentoSeleccionado: Department | undefined =
        provincesAndCities.find(
          (prov) => prov.departamento === selectedProvince
        );

      if (departamentoSeleccionado) {
        setCities(departamentoSeleccionado.ciudades);
        setValue("province", selectedProvince);
      }
    }, [selectedProvince, provincesAndCities]);

    useImperativeHandle(ref, () => ({
      resetForm() {
        reset();
      },
    }));

    useEffect(() => {
      if (!params?.brand) {
        return;
      }

      const url = getLinkTerms(params.country, params?.brand);
      setUrlTerms(url);
    }, []);

    const onSubmitUpdate = async (id: string, body: BodyCustomer) => {
      try {
        await updateCustomer("ec", id, body);
        setState({
          status: "success",
          message: `<h4>Tus datos han sido registrados exitosamente</h4> <br /> <p>Por favor revisa tu correo electrónico y accede a tu beneficio.</p>`,
        });
        setShowModal(true);
      } catch (error: any) {
        setState({
          status: "error",
          message: `<p>${error.response?.data?.message}</p>`,
        });
        setShowModal(true);
      } finally {
        setLoading(false);
      }
    };

    const onSubmitCreate = async (body: BodyCustomer) => {
      try {
        await createCustomer("ec", body);
        setState({
          status: "success",
          message: `<h4>Tus datos han sido registrados exitosamente</h4> <br /> <p>Por favor revisa tu correo electrónico y accede a tu beneficio.</p>`,
        });
        setShowModal(true);
      } catch (error: any) {
        setState({
          status: "error",
          message: `<p>${error.response?.data?.message}</p>`,
        });
        setShowModal(true);
      } finally {
        setLoading(false);
      }
    };

    const validateCustomer = async (data: FormDataEC): Promise<void> => {
      const body = buildRequestBody(data, params!);
      const bodyWoowUp = buildRequestBodyWoowUp(data, params!);

      try {
        await getCustomer("ec", data.identification)
          .then(async () => {
            await onSubmitUpdate(data.identification, body);
            await sendDataWoowUp(bodyWoowUp);
          })
          .catch((error: any) => {
            setLoading(false);
            const message = error?.response?.data?.message;

            if (message?.includes("CLIENTE NO EXISTE")) {
              onSubmitCreate(body);
              sendDataWoowUp(bodyWoowUp);
            }

            if (message?.includes("DATOS ACTUALIZADOS CON ANTERIORIDAD")) {
              setState({
                status: "error",
                message: MESSAGE_DATA_UPDATED,
              });
              setLoading(false);
              setShowModal(true);
            }
          });
      } catch (error) {
        console.error(error);
      }
    };

    const onSubmit: SubmitHandler<FormDataEC> = async (data) => {
      setLoading(true);
      await validateCustomer(data);
    };

    return (
      <>
        <section>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="container mt-5"
            noValidate
          >
            <div className="d-flex flex-column justify-contenet-center">
              <div className="mb-3 w-100">
                <label htmlFor="firstName" className="form-label">
                  Nombres:
                </label>
                <input
                  type="text"
                  id="firstName"
                  autoComplete="off"
                  {...register("firstName", {
                    required: "Este campo es obligatorio",
                    pattern: {
                      value: alphabeticPattern,
                      message: "Por favor, introduce un nombre válido",
                    },
                  })}
                  className={`form-control ${
                    errors.firstName ? "is-invalid" : ""
                  }`}
                />
                {errors.firstName && (
                  <div className="invalid-feedback">
                    {errors.firstName.message}
                  </div>
                )}
              </div>
              <div className="mb-3 w-100">
                <label htmlFor="lastName" className="form-label">
                  Apellidos:
                </label>
                <input
                  type="text"
                  id="lastName"
                  autoComplete="off"
                  {...register("lastName", {
                    required: "Este campo es obligatorio",
                    pattern: {
                      value: alphabeticPattern,
                      message: "Por favor, introduce un apellido válido",
                    },
                  })}
                  className={`form-control ${
                    errors.lastName ? "is-invalid" : ""
                  }`}
                />
                {errors.lastName && (
                  <div className="invalid-feedback">
                    {errors.lastName.message}
                  </div>
                )}
              </div>
              <div className="mb-3 w-100">
                <label htmlFor="email" className="form-label">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  autoComplete="off"
                  {...register("email", {
                    required: "Este campo es obligatorio",
                    pattern: {
                      value: emailPattern,
                      message:
                        "Por favor, introduce un correo electrónico válido",
                    },
                  })}
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email.message}</div>
                )}
              </div>
              <div className="mb-3 w-100">
                <label htmlFor="typeId" className="form-label">
                  Tipo de documento:
                </label>
                <select
                  id="typeId"
                  {...register("typeId", {
                    required: "Seleccione un tipo de identificación",
                  })}
                  defaultValue=""
                  className={`form-select pointer ${
                    errors.typeId ? "is-invalid" : ""
                  }`}
                >
                  <option disabled value="">
                    Seleccione un tipo de identificación
                  </option>
                  {TYPE_IDS_EC.map((typeId) => (
                    <option key={typeId} value={typeId}>
                      {typeId}
                    </option>
                  ))}
                </select>
                {errors.typeId && (
                  <div className="invalid-feedback">
                    {errors.typeId.message}
                  </div>
                )}
              </div>
              <div className="mb-3 w-100">
                <label htmlFor="identification" className="form-label">
                  Número de documento:
                </label>
                <input
                  type="text"
                  id="identification"
                  autoComplete="off"
                  {...register("identification", {
                    required: "Este campo es obligatorio",
                    pattern: {
                      value: numericPattern,
                      message: "Por favor, introduce un número válido",
                    },
                  })}
                  className={`form-control ${
                    errors.identification ? "is-invalid" : ""
                  }`}
                />
                {errors.identification && (
                  <div className="invalid-feedback">
                    {errors.identification.message}
                  </div>
                )}
              </div>
              <div className="mb-3 w-100">
                <label htmlFor="phoneNumber" className="form-label">
                  Celular:
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  autoComplete="off"
                  {...register("phoneNumber", {
                    required: "Este campo es obligatorio",
                    pattern: {
                      value: numericPattern,
                      message: "Por favor, introduce un número válido",
                    },
                  })}
                  className={`form-control ${
                    errors.phoneNumber ? "is-invalid" : ""
                  }`}
                />
                {errors.phoneNumber && (
                  <div className="invalid-feedback">
                    {errors.phoneNumber.message}
                  </div>
                )}
              </div>
              <div className="mb-3 w-100">
                <label htmlFor="province" className="form-label">
                  Provincia:
                </label>
                <select
                  id="province"
                  {...register("province", {
                    required: "Seleccione una provincia",
                  })}
                  defaultValue=""
                  className={`form-select pointer ${
                    errors.province ? "is-invalid" : ""
                  }`}
                >
                  <option disabled value="">
                    Seleccione una provincia
                  </option>
                  {provincesAndCities?.map((province) => (
                    <option
                      key={province.departamento}
                      value={province.departamento}
                    >
                      {province.departamento}
                    </option>
                  ))}
                </select>
                {errors.province && (
                  <div className="invalid-feedback">
                    {errors.province.message}
                  </div>
                )}
              </div>
              <div className="mb-3 w-100">
                <label htmlFor="city" className="form-label">
                  Ciudad:
                </label>
                <select
                  id="city"
                  {...register("city", { required: "Seleccione una ciudad" })}
                  defaultValue=""
                  className={`form-select ${errors.city ? "is-invalid" : ""}`}
                >
                  <option disabled value="">
                    Seleccione una ciudad
                  </option>
                  {cities.map((city, index) => (
                    <option key={index + city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
                {errors.city && (
                  <div className="invalid-feedback">{errors.city.message}</div>
                )}
              </div>
              <div className="mb-3 w-100">
                <label htmlFor="department" className="form-label">
                  Genero:
                </label>
                <select
                  id="gender"
                  {...register("gender", {
                    required: "Seleccione un genero",
                  })}
                  defaultValue=""
                  className={`form-select pointer ${
                    errors.gender ? "is-invalid" : ""
                  }`}
                >
                  <option disabled value="">
                    Seleccione un genero
                  </option>
                  <option value="M">Hombre</option>
                  <option value="F">Mujer</option>
                  <option value="I">Otro</option>
                </select>
                {errors.gender && (
                  <div className="invalid-feedback">
                    {errors.gender.message}
                  </div>
                )}
              </div>
              <div className="mb-3 w-100">
                <label htmlFor="birthdayDate" className="form-label">
                  Fecha Cumpleaños:
                </label>
                <input
                  type="date"
                  placeholder="dd-mm-yyyy"
                  id="birthdayDate"
                  {...register("birthdayDate", {
                    required: "Este campo es obligatorio",
                  })}
                  className={`form-control ${
                    errors.birthdayDate ? "is-invalid" : ""
                  }`}
                />
                {errors.birthdayDate && (
                  <div className="invalid-feedback">
                    {errors.birthdayDate.message}
                  </div>
                )}
              </div>
              <div className="mb-3 w-100">
                <label htmlFor="nationality" className="form-label">
                  Nacionalidad:
                </label>
                <select
                  id="nationality"
                  {...register("nationality", {
                    required: "Debe seleccionar una nacionalidad",
                  })}
                  defaultValue=""
                  className={`form-select pointer ${
                    errors.nationality ? "is-invalid" : ""
                  }`}
                >
                  <option disabled value="">
                    Seleccione una nacionalidad
                  </option>
                  {COUNTRIES?.map((country) => (
                    <option key={country.id} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {errors.nationality && (
                  <div className="invalid-feedback">
                    {errors.nationality.message}
                  </div>
                )}
              </div>
            </div>
            <div className="form-check my-3">
              <input
                className={`form-check-input ${
                  errors.termsAndConditions ? "is-invalid" : ""
                }`}
                type="checkbox"
                id="termsAndConditions"
                {...register("termsAndConditions", {
                  required: "Debe aceptar los términos y condicoines",
                })}
              />
              <label className="form-label" htmlFor="termsAndConditions">
                Acepto la&nbsp;
                <a href={urlTerms} target="_blank" rel="noopener noreferrer">
                  política de tratamiento de datos y términos y condiciones
                </a>
              </label>
              {errors.termsAndConditions && (
                <div className="invalid-feedback">
                  {errors.termsAndConditions.message}
                </div>
              )}
            </div>
            <div className="d-grid d-md-flex justify-content-md-end">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary btn-lg my-3"
              >
                Enviar
              </button>
            </div>
          </form>
        </section>
      </>
    );
  }
);
