import { useEffect, useState } from "react";
import { Department } from "../../core/domain/Department";
import { fetchData } from "../../infrastructure/httpUtils";

export const useGetDepartments = () => {
  const [departments, setDepartments] = useState<Department[]>([]);

  useEffect(() => {
    const fetchDepartments = async () => {
      const departments = await fetchData("/departments.json");
      setDepartments(departments);
    };

    fetchDepartments();
  }, []);

  return departments;
};
export const useGetProvinces = () => {
  const [provinces, setProvinces] = useState<Department[]>([]);

  useEffect(() => {
    const fetchDepartments = async () => {
      const departments = await fetchData("/provinces-cities-ec.json");
      setProvinces(departments);
    };

    fetchDepartments();
  }, []);

  return provinces;
};
