import React from "react";
import { LOGOS } from "../../utils/constants";

interface LogoProps {
  brand: string;
}

const getLogo = (brand: string): string => {
  return LOGOS[brand] || "/assets/images/logoPernineDynamo.png";
};

export const Logo: React.FC<LogoProps> = ({ brand }) => {
  return (
    <picture className="d-flex align-items-center justify-content-center">
      <img
        src={getLogo(brand)}
        width={160}
        height="auto"
        alt={brand}
        loading="lazy"
      />
    </picture>
  );
};
