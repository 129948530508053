import React from "react";
import { useSharedState } from "../../providers/SharedStateContext/SharedStateContext";

export const Loading: React.FC = () => {
  const { loading } = useSharedState();
  return (
    <>
      {loading ? (
        <div className="loading__container">
          <img
            src="/assets/icons/spinner.svg"
            className="loading__spinner"
            width={100}
            height={100}
            alt=""
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
